import React, { FC } from 'react';
import { IProps } from './types';
import { v4 as uuid } from 'uuid';
import {
  useAnalyzeQuery,
  AnalyzeCategory,
  AnalyzeCommand,
  useDeviceUsedFuelsQuery,
} from '@state/mechis-backend/generated/schema';
import {
  Alert,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mechis/elements';
import { DAYS_IN_MONTH, THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { subDays, formatISO } from 'date-fns';
import AnalysisWidgetFuelRow from './AnalysisWidgetFuelRow';
import AnalysisWidgetSkeletonRow from './AnalysisWidgetSkeletonRow';
import useTech, { UnitType } from '@hooks/useTech';

const getDateFilterForAnalysis = () => {
  const today = new Date();

  return {
    date: {
      from: formatISO(subDays(today, DAYS_IN_MONTH)),
      to: formatISO(today),
    },
  };
};

const AnalysisWidget: FC<IProps> = () => {
  const { t } = useTranslation();
  const { getTechUnit, techId } = useTech();

  const {
    data: deviceFuels,
    loading: deviceFuelsLoading,
    error: deviceFuelsError,
  } = useDeviceUsedFuelsQuery({ variables: { deviceId: techId } });

  const { data, loading, error } = useAnalyzeQuery({
    fetchPolicy: 'network-only',
    variables: {
      deviceId: techId,
      analyzeCategory: AnalyzeCategory.Sum,
      command: AnalyzeCommand.Sum,
      tags: [],
      filters: getDateFilterForAnalysis(),
    },
  });
  
  const getSuffix = (key: string) => {
    if (key === 'sumPrice') return getTechUnit(UnitType.Currency, true);
    if (key === 'sumOdometerTaken') return getTechUnit(UnitType.Odometer, true);

    return undefined;
  };

  return (
    <Paper sx={{ p: 1 }}>
      {(error && deviceFuelsError) && (
        <Alert severity="error">{t('analysisWidget.error')}</Alert>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            {loading && <AnalysisWidgetSkeletonRow />}
            {data?.analyze.map((row) => (
              <TableRow key={uuid()}>
                <TableCell>
                  <Stack
                    flexDirection="row"
                    sx={{ lineHeight: 1 }}
                    alignItems="center"
                  >
                    {t(`analysisWidget.${row.rowDescriptionKey}`)}
                  </Stack>
                </TableCell>
                <TableCell align="right" sx={{ width: '35%' }}>
                  <NumberFormat
                    value={row.value}
                    displayType="text"
                    thousandSeparator={THOUSAND_SEPARATOR}
                    suffix={getSuffix(row.rowDescriptionKey)}
                    decimalScale={2}
                  />
                </TableCell>
              </TableRow>
            ))}
            {deviceFuelsLoading && <AnalysisWidgetSkeletonRow />}
            {deviceFuels?.device?.fuel.map((fuel) => (
              <AnalysisWidgetFuelRow
                key={uuid()}
                fuelId={fuel.id}
                filters={getDateFilterForAnalysis()}
                type={fuel.fuelType?.fuelName}
                unit={fuel.consumptionUnit?.unitName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AnalysisWidget;
