import React, { FC, useEffect } from 'react';
import { ControlBlock } from './styles';
import useAttachments from './useAttachments';

interface AttachmentsControlProps {
  children: React.ReactNode
  variant?: 'attachments' | 'avatar'
  target?: 'direct' | 'modal'
  acceptedTypes?: string[]
}

const AttachmentsControl: FC<AttachmentsControlProps> = ({
  children,
  variant = 'attachments',
  target = 'direct',
  acceptedTypes = [ 'image/*', 'application/pdf', 'text/plain' ],
}) => {
  const { setIsModalOpen, handleFileChange, selectedFilesState } = useAttachments();

  useEffect(() => {
    if (selectedFilesState.length > 0) {
      setIsModalOpen(true);
    }
  }, [ selectedFilesState ]);

  return (
    <ControlBlock
      onClick={target === 'modal' ? () => setIsModalOpen(true) : undefined}
    >
      {target === 'direct' && (
        <>
          <input
            accept={acceptedTypes.join(',')}
            style={{ display: 'none' }}
            id="icon-button-file"
            type="file"
            multiple={variant === 'attachments' ? true : false}
            onChange={(e) => handleFileChange(e, variant)}
          />
          <label 
            htmlFor="icon-button-file"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {children}
          </label>
        </>
      )}
      {target === 'modal' && children}
    </ControlBlock>
  ); 
};

export default AttachmentsControl;
