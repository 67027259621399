import React, { useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import appCacheControl from '@state/mutations/app';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { DevicesDocument, useCreateDeviceMutation, DeviceCreateInput, UserDocument } from '@state/mechis-backend/generated/schema';
import { DEVICE_ID, USER_ID } from '@configurations/constants/app';
import { useNavigate } from 'react-router-dom';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { useTranslation } from 'react-i18next';
import WarningModal from '@mechis/blocks/WarningModal';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useLocalStorage } from 'react-use';

const useAddDevice = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ isResetModal, setIsResetModal ] = useState(false);
  const { setAddFlow, setAddFlowStep } = appCacheControl;
  const { setSnackbar } = snackbarCacheControl;
  const { resetToInit } = addDeviceCacheControl;
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const [ userId ] = useLocalStorage(USER_ID, '');
  const device = data?.addDevice;

  const [ createDevice ] = useCreateDeviceMutation({
    refetchQueries: [
      { query: DevicesDocument },
      { 
        query: UserDocument,
        variables: {
          userId: Number(userId),
        },
      },
    ],
    // eslint-disable-next-line
    onCompleted: (data) => {
      localStorage.setItem(DEVICE_ID, data?.createDevice?.id ?? '');
      navigate(`/app/${data?.createDevice?.id}/tech-detail`);
      setSnackbar('success', 'DEVICE_ADDED');
      resetToInit();
    },
  });

  const getDevice = (name?: string): DeviceCreateInput => {
    // @ts-ignore
    const { fuels, ...deviceCreateInput } = device;
    // eslint-disable-next-line
    const remapedFuels = fuels.map(({ id, isSelected, fuelName, __typename, ...keepAttrs }: any) => {
      if (!isSelected) return false;
      return keepAttrs;
    });
    deviceCreateInput.fuel = _.filter(remapedFuels, (o) => o);
    deviceCreateInput.thumbnailImage = { id: Number(device?.thumbnailImage?.id) };
    //remove local only field from deviceCreateInput object
    delete deviceCreateInput.validationErrors;

    if (name) deviceCreateInput.name = name;
    return deviceCreateInput;
  };

  const onCreate = async (name?: string) => {
    await createDevice({
      variables: {
        device: getDevice(name),
      },
    });
    setAddFlow(false);
    setAddFlowStep(false);
  };

  const onReset = () => {
    setIsResetModal(true);
  };

  const onConfirmReset = () => {
    resetToInit();
    setAddFlow(false);
    setAddFlowStep(false);
    setIsResetModal(false);
    navigate('/app/new-tech');
  };

  const ResetModal = () => {
    return (
      <WarningModal
        open={isResetModal}
        onClose={setIsResetModal}
        title={t('addDevice.resetModal.title')}
        onConfirm={onConfirmReset}
        confirmBtnText={t('addDevice.resetModal.btnSubmit')}
      >
        {t('addDevice.resetModal.text')}
      </WarningModal>
    );
  };

  return {
    onCreate,
    onReset,
    ResetModal,
  };
};

export default useAddDevice;
