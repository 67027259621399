import React, { useState } from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import { Grid } from '@mechis/elements';
import Lightbox from 'yet-another-react-lightbox';

const ATTACHMENTS_WIDGET_LIMIT = 6;

const AttachmentsWidget = () => {
  const { fetchedAttachments } = useAttachments();

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  return (
    <>
      <Grid container spacing={2}>
        {fetchedAttachments.slice(0, ATTACHMENTS_WIDGET_LIMIT).map((file, index) => {
          return (
            <Grid
              item
              xs={4}
              key={index}
              style={{ minHeight: '110px' }}
              onClick={() => {
                if (file.mimeType === 'application/pdf') {
                  window.open(file.url, '_blank');
                } else {
                  setClickedImageIndex(
                    fetchedAttachments
                      .filter((attachment) => attachment.mimeType.startsWith('image/'))
                      .findIndex((attachment) => attachment.url === file.url)
                  );
                  // todo: disable lightbox for now
                  // setLightBoxOpened(true);
                }
              }}
            >
              <img src={file.url} alt={file.originalName} style={{ width: '100%', borderRadius: '8px' }} /> 
            </Grid>
          );
        })}
        <Lightbox
          open={lightBoxOpened}
          close={() => setLightBoxOpened(false)}
          index={clickedImageIndex}
          slides={fetchedAttachments
            .filter((attachment) => attachment.mimeType.startsWith('image/'))
            .slice(0, ATTACHMENTS_WIDGET_LIMIT)
            .map((attachment) => ({
              src: attachment.url,
            }))
          }
        />
      </Grid>
    </>
  );
};

export default AttachmentsWidget;
