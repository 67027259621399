import React, { useState } from 'react';
import { useAttachments, AttachmentAllowedType } from '@mechis/sections/Attachments';
import { AttachmentsGridItem, AttachmentsListItem } from '@mechis/sections/Attachments/components';
import {
  List,
  Grid,
  Box,
} from '@mechis/elements';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '@mechis/blocks/Loader';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const AttachmentsFetched = () => {
  const {
    view,
    fetchedAttachments,
    fetchMoreAttachments,
    hasMoreAttachments,
  } = useAttachments();

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  const onClick = (url: string) => {
    setClickedImageIndex(
      fetchedAttachments
        .filter((attachment) => attachment.mimeType.startsWith('image/'))
        .findIndex((attachment) => attachment.url === url)
    );
    setLightBoxOpened(true);
  };

  if (fetchedAttachments.length === 0) return null;

  if (view === 'list') {
    return (
      <>
        <InfiniteScroll
          dataLength={fetchedAttachments.length}
          next={fetchMoreAttachments}
          hasMore={hasMoreAttachments}
          loader={<Loader size={20} />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          <List>
            {fetchedAttachments.map((file, index) => {
              return (
                <AttachmentsListItem
                  key={index}
                  variant="fetched"
                  src={file.url}
                  alt={file.originalName}
                  type={file.mimeType as AttachmentAllowedType}
                  name={file.originalName}
                  onClick={() => onClick(file.url)}
                />
              );
            })}
          </List>
          <Lightbox
            open={lightBoxOpened}
            close={() => setLightBoxOpened(false)}
            index={clickedImageIndex}
            slides={fetchedAttachments
              .filter((attachment) => attachment.mimeType.startsWith('image/'))
              .map((attachment) => ({
                src: attachment.url,
              }))
            }
          />
        </InfiniteScroll>
      </>
    );
  }

  if (view === 'grid') {
    return (
      <Box mt={2}>
        <InfiniteScroll
          dataLength={fetchedAttachments.length}
          next={fetchMoreAttachments}
          hasMore={hasMoreAttachments}
          loader={<Loader size={20} />}
          style={{ overflow: 'hidden' }}
          scrollableTarget="scrollableDiv"
        >
          <Grid container spacing={2}>
            {fetchedAttachments.map((file, index) => {
              return (
                <AttachmentsGridItem
                  key={index}
                  variant="fetched"
                  src={file.url}
                  alt={file.originalName}
                  type={file.mimeType as AttachmentAllowedType}
                  name={file.originalName}
                  onClick={() => onClick(file.url)}
                />
              );
            })}
            <Lightbox
              open={lightBoxOpened}
              close={() => setLightBoxOpened(false)}
              index={clickedImageIndex}
              slides={fetchedAttachments
                .filter((attachment) => attachment.mimeType.startsWith('image/'))
                .map((attachment) => ({
                  src: attachment.url,
                }))
              }
            />
          </Grid>
        </InfiniteScroll>
      </Box>
    );
  }

  return null;
};

export default AttachmentsFetched;

