import React from 'react';
import { useAttachments, AttachmentAllowedType } from '@mechis/sections/Attachments';
import { AttachmentsGridItem, AttachmentsListItem } from '@mechis/sections/Attachments/components';
import {
  List,
  Grid,
  Box,
} from '@mechis/elements';

const AttachmentsSelected = () => {
  const {
    view,
    selectedFiles,
    handleRemoveFile,
  } = useAttachments();

  if (selectedFiles.length === 0) return null;

  if (view === 'list') {
    return (
      <div>
        <List>
          {selectedFiles.map((file, index) => {
            return (
              <AttachmentsListItem
                key={index}
                variant="selected"
                src={file.preview as string}
                alt={file.name}
                type={file.type as AttachmentAllowedType}
                name={file.name}
                size={file.size}
                onDelete={() => handleRemoveFile(file)}
              />
            );
          })}
        </List>
      </div>
    );
  }

  if (view === 'grid') {
    return (
      <Box mt={2}>
        <Grid container spacing={2}>
          {selectedFiles.map((file, index) => {
            return (
              <AttachmentsGridItem
                key={index}
                variant="selected"
                src={file.preview as string}
                alt={file.name}
                type={file.type as AttachmentAllowedType}
                name={file.name}
                // onClick={() => onClick(file.url)}
              />
            );
          })}
        </Grid>
      </Box>
    );
  }

  return null;
};

export default AttachmentsSelected;
