import React, { FC, useEffect } from 'react';
import { useAttachments } from '@mechis/sections/Attachments';
import {
  AttachmentsContentFilters,
  AttachmentsContentControl,
  AttachmentsAvatar,
  AttachmentsSelected,
  AttachmentsFetched,
  AttachmentsFetchedEvent,
  AttachmentsWidget,
} from '@mechis/sections/Attachments/content';
import { Stack, Box } from '@mechis/elements';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';

interface AttachmentsContentProps {
  variant?: 'attachments' | 'attachmentsWidget' | 'avatar'
  isUpdateAvatar?: boolean
  loadData?: boolean
  loadDataEvent?: boolean
}

const AttachmentsContent: FC<AttachmentsContentProps> = ({ 
  variant,
  isUpdateAvatar = false,
  loadData = false,
  loadDataEvent = false,
}) => {
  const {
    fetchFiles,
    fetchFilesEvent,
    attachmentsOffset,
    setAttachmentsOffset,
    setFetchedAttachments,
    reloadEventAttachments,
    selectedFiles,
    selectedFilesState,
    fetchedAttachments,
    allFetchedAttachments,
  } = useAttachments();
  const { data: selections } = useQuery<ISelectionsQuery>(GET_SELECTIONS);

  useEffect(() => {
    if (loadData) {
      setAttachmentsOffset(0);
      setFetchedAttachments([]);
      setTimeout(() => {
        fetchFiles(false);
      }, 10);
    }
  }, [ loadData, selections ]);

  useEffect(() => {
    if (loadData) {
      fetchFiles(true);
    }
  }, [ loadData, attachmentsOffset ]);

  useEffect(() => {
    if (loadDataEvent) {
      setTimeout(() => {
        fetchFilesEvent();
      }, 10);
    }
  }, [ loadDataEvent, reloadEventAttachments ]);

  // AVATAR
  if (variant === 'avatar') {
    return <AttachmentsAvatar isUpdateAvatar={isUpdateAvatar} />;
  }

  // ATTACHMENTS
  if (variant === 'attachments') {

    // variant: File Selection only (new events for example)
    if (!loadData && !loadDataEvent) {
      if (selectedFilesState.length === 0) return <AttachmentsContentControl />;
      return (
        <Stack>
          <AttachmentsContentFilters />
          {selectedFiles.length === 0 && (
            <Box textAlign="center" mt={2}>
              <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy tohoto typu</em>
            </Box>
          )}
          <AttachmentsSelected />
        </Stack>
      );
    }

    // variant: All Attachments on tech-detail/attachments
    if (loadData) {
      if (allFetchedAttachments.length === 0) {
        return (
          <Box textAlign="center" mt={2}>
            <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy pro danou techniku</em>
          </Box>
        );
      }
      return (
        <Stack>
          <AttachmentsContentFilters />
          {fetchedAttachments.length === 0 && (
            <Box textAlign="center" mt={2}>
              <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy tohoto typu</em>
            </Box>
          )}
          <AttachmentsFetched />
        </Stack>
      );
    }

    // variant: Event Detail Attachments
    if (loadDataEvent) {
      if (selectedFilesState.length === 0 && allFetchedAttachments.length === 0) return <AttachmentsContentControl />;
      return (
        <Stack>
          <AttachmentsContentFilters />
          {(selectedFiles.length === 0 && fetchedAttachments.length === 0) && (
            <Box textAlign="center" mt={2}>
              <em style={{ opacity: 0.4 }}>Neexistují žádné přílohy tohoto typu</em>
            </Box>
          )}
          <AttachmentsSelected />
          <AttachmentsFetchedEvent />
        </Stack>
      );
    }
  }

  // ATTACHMENTS WIDGET
  if (variant === 'attachmentsWidget') {
    return (
      <AttachmentsWidget />
    );
  }

  return null;
};

export default AttachmentsContent;
