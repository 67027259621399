import React, { useState, useEffect } from 'react';
import { useAttachments, AttachmentAllowedType } from '@mechis/sections/Attachments';
import { AttachmentsGridItem, AttachmentsListItem } from '@mechis/sections/Attachments/components';
import {
  List,
  Grid,
  Box,
} from '@mechis/elements';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const AttachmentsFetchedEvent = () => {
  const {
    view,
    fetchedAttachments,
    onDeleteEventAttachment,
  } = useAttachments();

  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);

  const onClick = (url: string) => {
    setClickedImageIndex(
      fetchedAttachments
        .filter((attachment) => attachment.mimeType.startsWith('image/'))
        .findIndex((attachment) => attachment.url === url)
    );
    setLightBoxOpened(true);
  };

  if (fetchedAttachments.length === 0) return null;

  if (view === 'list') {
    return (
      <>
        <List>
          {fetchedAttachments.map((file, index) => {
            return (
              <AttachmentsListItem
                key={index}
                variant="fetched"
                src={file.url}
                alt={file.originalName}
                type={file.mimeType as AttachmentAllowedType}
                name={file.originalName}
                onClick={() => onClick(file.url)}
                onDelete={() => onDeleteEventAttachment(file)}
              />
            );
          })}
        </List>
        <Lightbox
          open={lightBoxOpened}
          close={() => setLightBoxOpened(false)}
          index={clickedImageIndex}
          slides={fetchedAttachments
            .filter((attachment) => attachment.mimeType.startsWith('image/'))
            .map((attachment) => ({
              src: attachment.url,
            }))
          }
        />
      </>
    );
  }

  if (view === 'grid') {
    return (
      <Box mt={2}>
        <Grid container spacing={2}>
          {fetchedAttachments.map((file, index) => {
            return (
              <AttachmentsGridItem
                key={index}
                variant="fetched"
                src={file.url}
                alt={file.originalName}
                type={file.mimeType as AttachmentAllowedType}
                name={file.originalName}
                onClick={() => onClick(file.url)}
              />
            );
          })}
          <Lightbox
            open={lightBoxOpened}
            close={() => setLightBoxOpened(false)}
            index={clickedImageIndex}
            slides={fetchedAttachments
              .filter((attachment) => attachment.mimeType.startsWith('image/'))
              .map((attachment) => ({
                src: attachment.url,
              }))
            }
          />
        </Grid>
      </Box>
    );
  }

  return null;
};

export default AttachmentsFetchedEvent;

